<script setup>
import { computed, onUnmounted, ref, toRef, watch, unref } from 'vue';
import { DateTime } from 'luxon';
import BookingTableCell from '@/modules/booking/components/BookingTableCell.vue';
import AppIcon from '@/components/AppIcon.vue';
import { useWorkingTime } from '@/modules/booking/use/useWorkingTime.js';
import { useBookingTable } from '@/modules/booking/use/useBookingTable.js';
import { formatPhone } from '@/use/useFormat.js';
import { useMouseInElement } from '@vueuse/core';
import IconCross from '@/components/Icons/IconCross.vue';
import BookingRemove from '@/modules/booking/components/BookingRemove.vue';
import { useAuthStore } from '@/store/AuthStore.js';
import IconPlanet from '@/components/Icons/IconPlanet.vue';
import AppTooltip from '@/components/AppTooltip.vue';

const props = defineProps({
  selectedIntervals: {
    type: Array,
    required: true,
  },
  selectedDate: {
    type: String,
    required: true,
  },
  bookedIntervals: {
    type: Array,
    required: true,
  },

  workplaces: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['selected-intervals-changed']);
const { hasAccess } = useAuthStore();

const {
  openTime,
  closeTime,
  workingHours: workingHoursFrames,
} = useWorkingTime(toRef(props, 'selectedDate'), toRef(props, 'bookedIntervals'), toRef(props, 'selectedIntervals'));

const {
  workplaceTimeFrames,
  frameHover,
  frameLeave,
  frameHoveredData,
  selectedIntervals,
  getIntervalRow,
  getIntervalCol,
  getIntervalSpan,
  getIntervalStartTime,
  getIntervalEndTime,
  selectInterval,
  unselectInterval,
} = useBookingTable(
  toRef(props, 'selectedIntervals'),
  toRef(props, 'bookedIntervals'),
  openTime,
  closeTime,
  props.workplaces
);

watch(
  selectedIntervals,
  () => {
    emit('selected-intervals-changed', selectedIntervals.value);
  },
  {
    deep: true,
  }
);

const gridSize = computed(() => {
  return {
    '--gridSize': '95px repeat(' + props.workplaces.length + ', minmax(194px,1fr))',
  };
});

const currentDate = ref(DateTime.now().toISO());

const intervalId = setInterval(() => {
  currentDate.value = DateTime.now().toISO();
}, 60 * 1000);

onUnmounted(() => {
  clearInterval(intervalId);
});

const isCurrentDate = computed(() => {
  const selectedDate = DateTime.fromISO(props.selectedDate).toISODate();
  const currentDay = DateTime.fromISO(currentDate.value).toISODate();

  return currentDay === selectedDate;
});

const isNowOpen = computed(() => {
  return unref(openTime) < currentDate.value && unref(closeTime) > currentDate.value;
});

const currentTimeOffset = computed(() => {
  const currentDateObject = DateTime.fromISO(currentDate.value);
  const offset = (100 / 15) * (currentDateObject.minute % 15);

  return offset;
});

const currentTimeRowStart = computed(() => {
  const currentDateObject = DateTime.fromISO(currentDate.value);
  const minute = currentDateObject.minute - (currentDateObject.minute % 15);
  const rowTimestamp = DateTime.now().set({ minute, second: 0, millisecond: 0 }).toISO();

  return getIntervalRow({ timestamp: rowTimestamp });
});
const table = ref(null);

const { isOutside } = useMouseInElement(table);
watch(isOutside, () => {
  if (isOutside.value) {
    frameLeave();
  }
});

const removeBookingId = ref(null);

const __selectInterval = (workplaceId, timestamp, duration) => {
  if (!hasAccess('create-Booking')) return;
  selectInterval(workplaceId, timestamp, duration);
};

const __unselectInterval = (interval) => {
  if (!hasAccess('create-Booking')) return;
  unselectInterval(interval);
};
</script>

<template>
  <div
    ref="table"
    class="grid grid-cols-[var(--gridSize)] overflow-y-scroll"
    :style="gridSize"
  >
    <!-- Названия столбцов -->
    <div class="font-medium text-center pb-[18px] sticky left-0 bg-white z-20">Время</div>

    <!--  Шкала времени -->
    <BookingTableCell
      v-for="(hour, index) in workingHoursFrames"
      :key="hour"
      :col-start="1"
      :row-start="index * 4 + 2"
      :row-span="4"
      class="text-sm flex items-center justify-center row-span-4 sticky left-0 bg-white z-20 border-r border-b border-[#F3F7FF]"
    >
      <span
        v-if="
          !(
            isCurrentDate &&
            DateTime.fromISO(currentDate).set({ minute: 0 }).toFormat('HH:mm') ===
              DateTime.fromISO(hour).toFormat('HH:mm')
          )
        "
        >{{ DateTime.fromISO(hour).toFormat('HH:mm') }}</span
      >
    </BookingTableCell>

    <template
      v-for="(workplace, workplaceIndex) in workplaces"
      :key="workplace.workplaceId"
    >
      <div class="font-medium uppercase text-center bg-white border-r border-b border-[#F3F7FF]">
        {{ workplace.title }}
      </div>

      <!-- Все клетки -->
      <BookingTableCell
        v-for="(frame, frameIndex) in workplaceTimeFrames"
        :key="frame"
        :col-start="workplaceIndex + 2"
        :row-start="frameIndex + 2"
        :data-workplace-id="workplace.workplaceId"
        :data-frame-id="frame"
        class="cell border-r border-[#F3F7FF] cursor-pointer"
        :class="{ 'border-b ': (frameIndex + 1) % 4 === 0 }"
        @mouseenter="frameHover(workplace.workplaceId, frame)"
      >
      </BookingTableCell>
    </template>

    <!-- Фрейм под курсором -->
    <BookingTableCell
      v-if="frameHoveredData"
      :col-start="frameHoveredData.workplaceIndex + 2"
      :row-start="frameHoveredData.frameIndex + 2"
      :row-span="frameHoveredData.allowFramesSelectCount"
      class="select-none relative bg-[#FBFBFD] text-xs flex items-center justify-center cursor-pointer"
      @click="__selectInterval(frameHoveredData.workplaceId, frameHoveredData.timestamp, frameHoveredData.duration)"
      @mousemove="frameLeave()"
    >
      <div class="select-none">{{ frameHoveredData.timeStart }} - {{ frameHoveredData.timeEnd }}</div>
      <div
        class="absolute inset-x-0 top-0 hidden h-1/3 bg-red-notify"
        @mouseleave="frameLeave()"
      ></div>
    </BookingTableCell>

    <!-- Выбранное время -->
    <BookingTableCell
      v-for="interval in selectedIntervals"
      :key="[interval.workplaceId, interval.timestamp]"
      :col-start="getIntervalCol(interval)"
      :row-start="getIntervalRow(interval)"
      :row-span="getIntervalSpan(interval)"
      class="z-10 bg-[#FDF7F3] flex items-center justify-center text-xs"
      @click="unselectInterval(interval)"
      @mouseenter="frameLeave()"
    >
      <div class="select-none">{{ getIntervalStartTime(interval) }} - {{ getIntervalEndTime(interval) }}</div>
    </BookingTableCell>

    <!-- Линия текущего времени -->
    <BookingTableCell
      v-if="isCurrentDate & isNowOpen"
      :col-start="1"
      :col-span="workplaces.length + 1"
      :row-start="currentTimeRowStart"
      :row-span="1"
      class="relative z-30"
    >
      <div
        class="w-full border-b border-red-notify border-dashed absolute top-[var(--currentTimeOffset)]"
        :style="{ '--currentTimeOffset': currentTimeOffset + '%' }"
      ></div>
      <div
        class="bg-white w-[95px] flex items-center justify-center absolute top-[var(--currentTimeOffset)] -mt-[13px]"
        :style="{ '--currentTimeOffset': currentTimeOffset + '%' }"
      >
        <div class="bg-white rounded-[6px] px-[10px] py-[4px] border border-red-notify text-red-notify text-sm">
          {{ DateTime.now().toFormat('HH:mm') }}
        </div>
      </div>
    </BookingTableCell>

    <!-- Забронированное время -->
    <BookingTableCell
      v-for="interval in bookedIntervals"
      :key="[interval.workplaceId, interval.timestamp]"
      :col-start="getIntervalCol(interval)"
      :row-start="getIntervalRow(interval)"
      :row-span="getIntervalSpan(interval)"
      class="relative flex flex-col group"
      :class="{
        'cell-blue': (getIntervalCol(interval) - 2) % 4 === 0,
        'cell-green': (getIntervalCol(interval) - 2) % 4 === 1,
        'cell-red': (getIntervalCol(interval) - 2) % 4 === 2,
        'cell-military': (getIntervalCol(interval) - 2) % 4 === 3,
        'cell-pink': (getIntervalCol(interval) - 2) % 4 === 4,
      }"
      @mouseenter="frameLeave()"
    >
      <div class="cell-top"></div>

      <div class="cell-body flex flex-col gap-y-[4px] text-xs px-[12px] py-[5px] pb-[7px]">
        <div class="text-gray-primary">{{ interval.timeStart }} - {{ interval.timeEnd }}</div>

        <div
          v-if="interval.isOnline"
          class="absolute right-[3px]"
        >
          <AppTooltip
            title="Онлайн-запись"
            class="text-xs"
          >
            <AppIcon
              :icon="IconPlanet"
              :width="17"
              :height="17"
            />
          </AppTooltip>
        </div>

        <template v-if="interval.client">
          <div
            v-if="interval.client"
            class="font-medium flex items-center gap-[2px]"
          >
            <AppTooltip
              title="Количество записей клиента за все время"
              class="text-xs"
            >
              <span class="px-1 font-light border rounded-full text-2xs">{{ interval.client.bookingsCount }}</span>
            </AppTooltip>
            <span>{{ interval.client.title }}</span>
          </div>
          <div v-if="interval.client.phone">{{ formatPhone(interval.client.phone) }}</div>
        </template>

        <template v-else>
          <div class="text-gray-primary">Анонимный клиент</div>
        </template>

        <div
          v-if="interval.note"
          class="mt-[3px] text-gray-primary"
        >
          {{ interval.note }}
        </div>
      </div>
      <div
        v-if="hasAccess('delete-booking-Order')"
        class="absolute cursor-pointer w-[31px] top-[30px] bottom-0 right-0 hidden group-hover:flex items-center justify-center px-[6px] bg-[#E4E4E4] bg-opacity-30 rounded-br-[6px]"
        data-test="remove-booking-button"
        @click="removeBookingId = interval.bookingId"
      >
        <AppIcon :icon="IconCross" />
      </div>
    </BookingTableCell>

    <BookingRemove
      v-if="removeBookingId"
      :booking-id="removeBookingId"
      @removed="removeBookingId = null"
      @canceled="removeBookingId = null"
    />
  </div>
</template>

<style scoped>
.cell {
  @apply min-h-[22px] bg-white;
}

.cell-top {
  /*@apply border-t-[3px]  h-[4px] rounded-t-[3px];*/
  @apply h-[3px] rounded-t-[6px];
}

.cell-body {
  @apply grow rounded-b-[6px];
}

/** blue **/
.cell-blue .cell-top {
  @apply bg-[#626AE9];
}
.cell-blue .cell-body {
  @apply bg-[#EEF4FF];
}

/** green **/
.cell-green .cell-top {
  @apply bg-[#3FB284];
}
.cell-green .cell-body {
  @apply bg-[#F8FBF4];
}

/** red **/
.cell-red .cell-top {
  @apply bg-[#FB2626];
}
.cell-red .cell-body {
  @apply bg-[#FDF7F3];
}

/** military **/
.cell-military .cell-top {
  @apply bg-[#A9B23F];
}
.cell-military .cell-body {
  @apply bg-[#FDFCF3];
}

/** pink **/
.cell-military .cell-top {
  @apply bg-[#EC4899];
}
.cell-military .cell-body {
  @apply bg-[#FDF3FB];
}
</style>
